import { API_BASE, API_VERSION } from '../constants/api'
import axios from 'axios'
import { toast } from 'react-toastify'

type ApiErrorType = {
  field: string | null
  message: string
}

export function createInstance(options: object) {
  const instance = axios.create(options)

  instance.interceptors.request.use(
    (request) => {
      const token = JSON.parse(localStorage.getItem('access_token') || 'null')
      if (token) {
        request.headers['Authorization'] = 'Bearer ' + token
      }
      return request
    },
    (error) => {
      return Promise.reject(error)
    }
  )

  instance.interceptors.response.use(
    (response: any) => response.data,
    (error) => {
      const errors = error.response?.data?.errors
      if (errors) {
        errors.forEach((err: ApiErrorType) => toast.error(err.message))
      }
      return Promise.reject(error)
    }
  )

  return instance
}

const instance = createInstance({
  baseURL: `${API_BASE}${API_VERSION}`,
  responseType: 'json',
})

export default instance
