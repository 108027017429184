import React, { useState } from 'react'
import styles from './VesselsTable.module.scss'
import cn from 'classnames'
import Checkbox from '@material-ui/core/Checkbox'
import IconButton from '@material-ui/core/IconButton'
import { ReactComponent as EditIcon } from '../../assets/edit.svg'
import { ReactComponent as DeleteIcon } from '../../assets/delete.svg'

import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../redux/reducers/root.reducer'
import { Tooltip } from '@material-ui/core'
import { ArrowSvg } from '../Icons'
import { IVessel } from '../../types/global.types'
import { editVesselAction } from '../../redux/actions/vessel-modal.action'
import {
  applyCombineAction,
  deleteVesselRequest,
  setIsVesselSelectedAction,
} from '../../redux/vessels/main.action'
import { utcToLocalDate } from '../../helpers/api.helper'

interface ILeadTableItemProps {
  item: IVessel
  isCombineActive: boolean
}

const VesselsTableItem: React.FC<ILeadTableItemProps> = ({
  item,
  isCombineActive,
}) => {
  const dispatch = useDispatch()

  const [isOpen, setIsOpen] = useState(false)

  const selectedLeadIds: Array<number> = useSelector(
    (state: RootState) => state.main.list
  )
    .filter((item: IVessel) => item.is_selected)
    .map((item: IVessel) => item.id)

  const handleLeadDelete = (id: number, name: string) => {
    if (window.confirm(`Do you want to delete ${name || 'this vessel'}?`)) {
      dispatch(deleteVesselRequest([id]))
    }
  }

  const applyCombine = () => {
    dispatch(
      applyCombineAction(
        item.id,
        selectedLeadIds.filter((el) => el !== item.id)
      )
    )
  }

  const handleLeadEdit = (id: number) => {
    dispatch(editVesselAction(id, item))
  }

  const handleCheckboxClick = (event: React.SyntheticEvent, id: number) => {
    event.stopPropagation()
    dispatch(setIsVesselSelectedAction(id))
  }

  return (
    <div
      key={item.id}
      className={cn(styles.row_wrapper, {
        [styles.active]: item.is_selected,
        [styles.combine_mode]: isCombineActive,
      })}
    >
      <div className={cn(styles.row, 'container')}>
        <div className={styles.field}>{item.name || '—'}</div>
        <div className={styles.field}>{item.flag?.name || '—'}</div>
        <div className={styles.field}>{item.year_of_build || '—'}</div>
        <div className={styles.field}>{item.imo || '—'}</div>
        <div className={styles.field}>{item.vessel_type?.name || '—'}</div>
        <div className={styles.field}>{item.dwt || '—'}</div>

        <div className={styles.field}>{item.grt || '—'}</div>
        <div className={styles.field}>{item.teu || '—'}</div>
        <div className={styles.field}>{item.engine_type?.name || '—'}</div>
        <div className={styles.field}>{item.hp || '—'}</div>
        <div className={styles.field}>{item.kw || '—'}</div>

        <div className={styles.last_field}>
          <Checkbox
            disabled={isCombineActive && selectedLeadIds.length < 3}
            size="small"
            checked={item.is_selected}
            onChange={(event) => handleCheckboxClick(event, item.id)}
            color="secondary"
          />
        </div>
        <div className={styles.controls}>
          {isCombineActive ? (
            <button onClick={applyCombine} className={styles.apply_combine}>
              Main
            </button>
          ) : (
            <>
              <Tooltip title="Edit">
                <IconButton
                  onClick={() => handleLeadEdit(item.id)}
                  aria-label="delete"
                  size="small"
                >
                  <EditIcon fontSize="inherit" />
                </IconButton>
              </Tooltip>

              <Tooltip title="Delete">
                <IconButton
                  onClick={() => handleLeadDelete(item.id, item.name)}
                  aria-label="delete"
                  size="small"
                >
                  <DeleteIcon fontSize="inherit" />
                </IconButton>
              </Tooltip>
            </>
          )}
        </div>

        <div onClick={() => setIsOpen(!isOpen)} className={styles.arrow}>
          <ArrowSvg rotate={isOpen} />
        </div>

        {item.status && (
          <Tooltip placement="right" title={item.status.name}>
            <div
              className={styles.lead_status}
              style={{
                background: item.status ? item.status.color : 'transparent',
              }}
            />
          </Tooltip>
        )}
      </div>

      {isOpen && (
        <div
          className={cn(styles.second_row, 'container')}
          style={{
            borderColor: item.status ? item.status.color : 'transparent',
          }}
        >
          {item.created_by && (
            <div className={styles.second_row_item}>
              <b>Created by: </b>
              <span>
                {item.created_by.first_name} {item.created_by.last_name}
                {item.created_at && ` (${utcToLocalDate(item.created_at)})`}
              </span>
            </div>
          )}

          {item.updated_by && (
            <div className={styles.second_row_item}>
              <b>Updated by: </b>
              <span>
                {item.updated_by.first_name} {item.updated_by.last_name}
                {item.updated_at && ` (${utcToLocalDate(item.updated_at)})`}
              </span>
            </div>
          )}

          {item.owner && (
            <div className={styles.second_row_item}>
              <b>Operator: </b>
              <span>{item.owner.name}</span>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default VesselsTableItem
