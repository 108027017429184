import React, { useState } from 'react'
import styles from './ControlPanel.module.scss'
import { PlusSvg } from '../Icons'
import { Button, TextField } from '@material-ui/core'
import Filter from '../Filter/Filter'
import { setIsModalOpen } from '../../redux/actions/vessel-modal.action'
import { useDispatch, useSelector } from 'react-redux'
import {
  toggleCombineMode,
  updateFilterSearch,
} from '../../redux/vessels/main.action'
import { RootState } from '../../redux/reducers/root.reducer'
import { IVessel } from '../../types/global.types'
import { setIsUiModalOpen } from '../../redux/actions/ui.action'
import StatusMultiEditor from '../StatusMultiEditor/StatusMultiEditor'

const ControlPanel = () => {
  const dispatch = useDispatch()

  const [search, setSearch] = useState<string>('')
  const combine_mode: { is_active: boolean } = useSelector(
    (state: RootState) => state.main.combine_mode
  )
  const selectedVessels: Array<number> = useSelector(
    (state: RootState) => state.main.list
  ).filter((item: IVessel) => item.is_selected)

  const handleAddClick = () => {
    dispatch(setIsModalOpen(true))
  }

  const handleCombineClick = () => {
    dispatch(toggleCombineMode())
  }

  const handleHistoryButtonClick = () => {
    dispatch(setIsUiModalOpen('history', true))
  }

  const handleOwnerHistoryClick = () => {
    dispatch(setIsUiModalOpen('owner_history', true))
  }

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setSearch(value)
    if (value.length > 2 || value.length === 0) {
      dispatch(updateFilterSearch(value))
    }
  }

  return (
    <div className={styles.wrapper}>
      <div className="container">
        <div className={styles.filter}>
          <Filter />

          <div className={styles.search_wrapper}>
            <TextField
              variant="outlined"
              placeholder="Search"
              size="small"
              className={styles.search}
              value={search}
              onChange={handleSearchChange}
            />
          </div>

          <StatusMultiEditor />

          <div>
            <Button
              onClick={handleCombineClick}
              disabled={selectedVessels.length < 2}
              className={styles.button}
              variant="outlined"
            >
              {combine_mode.is_active ? 'Cancel' : 'Combine'}
            </Button>
            <Button
              onClick={handleHistoryButtonClick}
              disabled={selectedVessels.length !== 1}
              className={styles.button}
              variant="outlined"
            >
              History
            </Button>
            <Button
              onClick={handleOwnerHistoryClick}
              disabled={selectedVessels.length !== 1}
              className={styles.button}
              variant="outlined"
            >
              Owner history
            </Button>

            <Button
              className={styles.button}
              onClick={handleAddClick}
              startIcon={<PlusSvg size={18} fill="#81BB41" />}
            >
              Add
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ControlPanel
