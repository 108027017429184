import React from 'react'
import styles from './Header.module.scss'
import cn from 'classnames'
import UserMenu from '../UserMenu/UserMenu'

const Header: React.FC = () => {
  const title = `Vessels system`

  return (
    <header className={styles.header}>
      <div className={cn('container', styles.wrapper)}>
        <div>{title}</div>
        <UserMenu />
      </div>
    </header>
  )
}

export default Header
