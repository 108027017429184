import React from 'react'

interface IStatusColorProps {
  color: string
  style?: any
}

const StatusColor: React.FC<IStatusColorProps> = ({ color, style = {} }) => {
  return (
    <figure
      style={{
        height: 14,
        width: 14,
        position: 'absolute',
        right: '32px',
        top: '50%',
        transform: 'translate(0, -50%)',
        borderRadius: '4px',
        background: color,
        ...style,
      }}
    />
  )
}

export default StatusColor
