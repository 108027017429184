export enum USER {
  LOGOUT = 'USER_LOGOUT',
  LOGIN = 'USER_LOGIN',
  UPDATE_INFO = 'UPDATE_INFO',
}

export enum FILTER {
  DELETE = 'FILTER_DELETE',
  UPDATE = 'FILTER_UPDATE',
  UPDATE_SORTING = 'FILTER_UPDATE_SORTING',
  UPDATE_SEARCH = 'FILTER_UPDATE_SEARCH',
}

export enum VESSEL_MODAL {
  SET_IS_OPEN = 'VESSEL_MODAL_SET_IS_OPEN',
  START_EDIT = 'VESSEL_MODAL_START_EDIT',
}

export enum VESSELS {
  SET_IS_SELECTED = 'VESSELS_SET_IS_SELECTED',
  DELETE = 'VESSELS_DELETE',
  UPDATE = 'VESSELS_UPDATE',
  CREATE = 'VESSELS_CREATE',
  UPDATE_LIST = 'VESSELS_UPDATE_LIST',
  SET_IS_LOADING = 'VESSELS_SET_IS_LOADING',
  UPDATE_PAGINATION = 'VESSELS_UPDATE_PAGINATION',
  LOAD_MORE = 'LOAD_MORE',
  TOGGLE_COMBINE_MODE = 'TOGGLE_COMBINE_MODE',
  SELECT_ALL = 'SELECT_ALL',
  UNSELECT_ALL = 'UNSELECT_ALL',
  SET_STATUSES = 'VESSELS_SET_STATUSES',
}

export enum UI {
  SET_IS_MODAL_OPEN = 'UI_SET_IS_MODAL_OPEN',
}

export enum STATUS {
  SET_ON_EDIT = 'STATUS_SET_ON_EDIT',
  UPDATE_LIST = 'STATUS_UPDATE_LIST',
  UPDATE = 'STATUS_UPDATE',
  DELETE = 'STATUS_DELETE',
}
