import api from './index.api'
import { IMTPosition } from '../MTModal/MTModal'

const endpoint = 'mt-widget/'

export const getMTWidgetPosition = async () => {
  return await api.get(`${endpoint}`)
}

export const putMTWidgetPosition = async (position: IMTPosition) => {
  console.log(position)
  return await api.put(`${endpoint}`, position)
}
