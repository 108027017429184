import { HistoryModalType } from '../../types/global.types'
import { formatISO } from 'date-fns'

export function submitParser(
  data: Array<HistoryModalType>,
  main_id: number | string
) {
  return data.map((item) => {
    return {
      ...item,
      port: item.port?.id || null,
      owner: item.owner?.id || null,
      main: item.id === main_id,
      date_from: dateFromChecker(item.date_from),
    }
  })
}

function dateFromChecker(date: any) {
  if (date instanceof Date) {
    return formatISO(date, { representation: 'date' })
  } else if (date.includes('T')) {
    return date.split('T')[0]
  } else {
    return date
  }
}
