import React, { useEffect, useState } from 'react'
import styles from './Filter.module.scss'
import Button from '@material-ui/core/Button'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import CircularProgress from '@material-ui/core/CircularProgress'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import {
  ICountry,
  IStatus,
  IFilterValues,
  IUser,
  OptionItemType,
} from '../../types/global.types'
import { RootState } from '../../redux/reducers/root.reducer'
import {
  getFiltersRequest,
  getOwnerAutocompleteSearch,
} from '../../api/search.api'
import { updateFilter } from '../../redux/vessels/main.action'
import { TextField } from '@material-ui/core'
import { FilterModalSchema as validationSchema } from '../../constants/schemas'
import { Autocomplete } from '@material-ui/lab'
import FieldSearch from '../FieldSearch'

interface IFilterMoreProps {
  onClose: () => void
}

const FilterMore: React.FC<IFilterMoreProps> = ({ onClose }) => {
  const dispatch = useDispatch()

  const filter = useSelector((state: RootState) => state.main.filter)
  const [countries, setCountries] = React.useState<Array<ICountry>>([])
  const [statuses, setStatuses] = React.useState<Array<IStatus>>([])
  const [users, setUsers] = React.useState<Array<IUser>>([])
  const [engines, setEngines] = useState([])
  const [vesselTypes, setVesselTypes] = useState([])
  const [loading, setLoading] = React.useState<boolean>(false)

  useEffect(() => {
    setLoading(true)
    getFiltersRequest([
      'user',
      'country',
      'status',
      'vessel_type',
      'engine_type',
    ]).then((data) => {
      setLoading(false)
      setStatuses(data.status)
      setCountries(data.country)
      setUsers(data.user)
      setVesselTypes(data.vessel_type)
      setEngines(data.engine_type)
    })
  }, [])

  const initialValues: IFilterValues = {
    status: filter.status || null,
    flag: filter.flag || null,
    imo: filter.imo || '',
    owner: filter.owner || null,
    vessel_type: filter.vessel_type || null,
    engine_type: filter.engine_type || null,
    user: filter.user ? filter.user.id : '',
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      dispatch(
        updateFilter({
          ...values,
          user: users.find((item) => item.id === values.user) || null,
          status: statuses.find((item) => item.id === values.status) || null,
        })
      )
      onClose()
    },
  })

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | any>
  ): void => {
    formik.handleChange(event)
  }

  const handleFieldSearchChange = (
    name: string,
    value: string | OptionItemType
  ): void => {
    formik.handleChange({ target: { name, value } })
  }

  const { values, errors, touched } = formik
  return (
    <form noValidate className={styles.more} onSubmit={formik.handleSubmit}>
      {loading ? (
        <div className={styles.loader_wrapper}>
          <CircularProgress />
        </div>
      ) : (
        <>
          <div className={styles.field}>
            <TextField
              onChange={handleInputChange}
              value={values.imo}
              label="IMO"
              size="small"
              name="imo"
              type="number"
              variant="outlined"
              error={!!(errors.imo && touched.imo)}
              InputProps={{ inputProps: { min: 1 } }}
              helperText={errors.imo && touched.imo ? errors.imo : null}
            />
          </div>

          <div className={styles.field}>
            <Autocomplete
              id="country-cms"
              options={countries}
              clearOnBlur
              getOptionLabel={(option: ICountry) => option.name}
              value={values.flag}
              onChange={(event: any, value: ICountry | null) => {
                formik.setValues({ ...values, flag: value })
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Country"
                  size="small"
                  variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                  }}
                />
              )}
            />
          </div>

          <div className={styles.field}>
            <Autocomplete
              id="vessel_type-vessel"
              options={vesselTypes}
              clearOnBlur
              getOptionLabel={(option: { id: number; name: string }) =>
                option.name
              }
              value={values.vessel_type}
              onChange={(
                event: any,
                value: { id: number; name: string } | null
              ) => {
                formik.setValues({ ...values, vessel_type: value })
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Vessel type"
                  size="small"
                  variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                  }}
                />
              )}
            />
          </div>

          <div className={styles.field}>
            <Autocomplete
              id="vessel_type-engine"
              options={engines}
              clearOnBlur
              getOptionLabel={(option: { id: number; name: string }) =>
                option.name
              }
              value={values.engine_type}
              onChange={(
                event: any,
                value: { id: number; name: string } | null
              ) => {
                formik.setValues({ ...values, engine_type: value })
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Engine type"
                  size="small"
                  variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                  }}
                />
              )}
            />
          </div>

          <div className={styles.field}>
            <FormControl variant="outlined" size="small">
              <InputLabel>Created by</InputLabel>
              <Select
                name="user"
                value={values.user}
                onChange={handleInputChange}
                MenuProps={{ disablePortal: true }}
              >
                <MenuItem value="">None</MenuItem>
                {users.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.last_name} {item.first_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          <div className={styles.field}>
            <FieldSearch
              name="owner"
              label="Shipowner"
              altName="company"
              flat={false}
              value={values.owner?.name || ''}
              onChange={handleFieldSearchChange}
              request={getOwnerAutocompleteSearch}
            />
          </div>

          <div className={styles.field}>
            <FormControl variant="outlined" size="small">
              <InputLabel>Status</InputLabel>
              <Select
                id="status-label"
                name="status"
                value={values.status}
                onChange={handleInputChange}
                MenuProps={{ disablePortal: true }}
              >
                <MenuItem value="">None</MenuItem>
                {statuses.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    <span>{item.name}</span>
                    <figure
                      style={{
                        height: 14,
                        width: 14,
                        position: 'absolute',
                        right: '32px',
                        top: '50%',
                        transform: 'translate(0, -50%)',
                        borderRadius: '4px',
                        background: item.color,
                      }}
                    />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          <div className={styles.field} style={{ marginBottom: 0 }}>
            <Button type="submit" variant="contained" color="primary">
              apply
            </Button>
          </div>
        </>
      )}
    </form>
  )
}

export default FilterMore
