import { isIMO } from '../helpers/vessel.helper'

export const checkValidation = (value: string) => {
  if (!value.trim().length) {
    return {
      error: true,
      message: 'Field is required',
    }
  } else if (Number.isInteger(+value) && !isIMO(+value)) {
    return {
      error: true,
      message: 'IMO must be exactly 7 char',
    }
  } else if (value.trim().length < 3) {
    return {
      error: true,
      message: 'Must be largest then 3 char',
    }
  } else {
    return {
      error: false,
      message: null,
    }
  }
}
