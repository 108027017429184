import { FILTER, VESSELS } from '../action-types'
import { IVessel } from '../../types/global.types'
import { IInitialState } from './types'

const initialState: IInitialState = {
  ordering: 'name',
  filter: {
    status: null,
    flag: null,
    imo: null,
    owner: null,
    vessel_type: null,
    engine_type: null,
    user: null,
    search: '',
  },
  pagination: {
    count: null,
    next: null,
    prev: null,
    is_loading: false,
  },
  combine_mode: {
    is_active: false,
  },
  list: [],
  loading: false,
}

export default function mainReducer(state = initialState, action: any) {
  switch (action.type) {
    case VESSELS.SET_IS_LOADING:
      return {
        ...state,
        loading: action.payload,
      }

    case VESSELS.SET_IS_SELECTED:
      return {
        ...state,
        list: state.list.map((item: IVessel) => {
          return item.id === action.payload
            ? { ...item, is_selected: !item.is_selected }
            : item
        }),
      }

    case VESSELS.DELETE:
      return {
        ...state,
        list: state.list.filter(
          (item: IVessel) => !action.payload.includes(item.id)
        ),
        pagination: {
          ...state.pagination,
          count: state.pagination.count ? state.pagination.count - 1 : null,
        },
      }

    case VESSELS.CREATE:
      return {
        ...state,
        list: [
          {
            ...action.payload.vessel,
            id: action.payload.id,
            is_selected: false,
          },
          ...state.list,
        ],
        pagination: {
          ...state.pagination,
          count: state.pagination.count ? state.pagination.count + 1 : null,
        },
      }

    case VESSELS.UPDATE:
      return {
        ...state,
        loading: false,
        list: state.list.map((vessel: IVessel) => {
          if (action.payload.id === vessel.id) {
            return {
              ...vessel,
              ...action.payload.vessel,
            }
          } else {
            return vessel
          }
        }),
      }

    case VESSELS.UPDATE_LIST:
      return {
        ...state,
        loading: false,
        list: action.payload.map((vessel: IVessel) => ({
          ...vessel,
          is_selected: false,
        })),
      }

    case VESSELS.LOAD_MORE:
      return {
        ...state,
        list: [
          ...state.list,
          ...action.payload.map((vessel: IVessel) => ({
            ...vessel,
            is_selected: false,
          })),
        ],
      }

    //  filters
    case FILTER.UPDATE_SORTING:
      return {
        ...state,
        ordering: action.payload,
      }

    case FILTER.DELETE:
      return {
        ...state,
        filter: {
          ...state.filter,
          [action.payload]: null,
        },
      }

    case FILTER.UPDATE:
      return {
        ...state,
        filter: action.payload,
      }

    case FILTER.UPDATE_SEARCH:
      return {
        ...state,
        filter: {
          ...state.filter,
          search: action.payload,
        },
      }

    //  Pagination
    case VESSELS.UPDATE_PAGINATION:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...action.payload,
        },
      }

    //  Combine mode cases
    case VESSELS.TOGGLE_COMBINE_MODE:
      return {
        ...state,
        combine_mode: {
          is_active: !state.combine_mode.is_active,
        },
      }

    case VESSELS.SELECT_ALL:
      return {
        ...state,
        list: state.list.map((vessel: IVessel) => ({
          ...vessel,
          is_selected: true,
        })),
      }

    case VESSELS.UNSELECT_ALL:
      return {
        ...state,
        list: state.list.map((vessel: IVessel) => ({
          ...vessel,
          is_selected: false,
        })),
      }

    case VESSELS.SET_STATUSES:
      return {
        ...state,
        list: state.list.map((item: IVessel) => {
          const { status, ids } = action.payload
          if (ids.includes(item.id)) {
            return {
              ...item,
              status,
            }
          } else {
            return item
          }
        }),
      }

    default:
      return state
  }
}
