import api from './index.api'
import { IStatus } from '../types/global.types'

const endpoint = `statuses/`

export const getStatusesRequest = async () => {
  return await api.get(`${endpoint}`)
}

export const deleteStatusRequest = async (id: number) => {
  return await api.delete(`${endpoint}${id}/`)
}

export const postNewStatusRequest = async (status: IStatus) => {
  return await api.post(`${endpoint}`, status)
}

export const postSetStatuses = async (statusId: number, ids: Array<number>) => {
  return await api.post(`set-statuses/`, {
    status_id: statusId,
    vessel_ids: ids,
  })
}

export const putStatusRequest = async (status: IStatus) => {
  return await api.put(`${endpoint}${status.id}/`, status)
}
