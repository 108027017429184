import React, { ChangeEvent } from 'react'
import styles from './HistoryModal.module.scss'
import { TextField } from '@material-ui/core'
import DatePicker from '../DatePicker'
import cn from 'classnames'
import FieldSearch from '../FieldSearch'
import { IHistoryItemProps, OptionItemType } from '../../types/global.types'

const HistoryItem: React.FC<IHistoryItemProps> = ({
  name = '',
  id,
  onChange,
  searchChange,
  port,
  date,
  isMain,
}) => {
  function handleFieldChange(event: ChangeEvent<HTMLInputElement>) {
    const { value, name } = event.target
    onChange(value, id, name)
  }
  function handlePickerChange(value: Date | null, name: string) {
    onChange(value, id, name)
  }
  function handleSearchChange(event: string, value: string | OptionItemType) {
    searchChange(event, value, id)
  }
  return (
    <div className={cn(styles.item_wrapper, { [styles.main]: isMain })}>
      <div className={styles.field}>
        <TextField
          label="name"
          name="name"
          value={name}
          variant="outlined"
          fullWidth
          size="small"
          onChange={handleFieldChange}
        />
      </div>
      <div className={styles.field}>
        <DatePicker
          id={id}
          value={date}
          name="date_from"
          label="Date from"
          onChange={handlePickerChange}
        />
      </div>
      <div className={styles.field}>
        <FieldSearch
          name="port"
          label="Port"
          altName="port"
          flat={false}
          value={port}
          onChange={handleSearchChange}
        />
      </div>
    </div>
  )
}

export default HistoryItem
