import api from './index.api'
import { HistoryModalType } from '../types/global.types'

const endpoint = 'vessels-name-history/'

export const getVesselHistory = async (id: number) => {
  return await api.get(`${endpoint}${id}/`)
}

export const postVesselHistory = async (
  id: number,
  data: Array<HistoryModalType>
) => {
  return await api.post(`${endpoint}${id}/`, data)
}
