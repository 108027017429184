import React from 'react'
import styles from './FilterIndicator.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../redux/reducers/root.reducer'
import { CloseSvg } from '../Icons'
import { deleteFilterItem } from '../../redux/vessels/main.action'
import { parseValue } from './FilterIndicator.helper'

const FilterIndicator: React.FC = () => {
  const dispatch = useDispatch()

  const filter: { [key: string]: any } = useSelector(
    (state: RootState) => state.main.filter
  )

  const { count } = useSelector((state: RootState) => state.main.pagination)
  const items = Object.keys(filter)
    .filter((item) => filter[item])
    .map((item) => {
      return {
        name: item,
        value: parseValue(filter, item),
      }
    })

  function handleClick(name: string) {
    dispatch(deleteFilterItem(name))
  }

  return (
    <div className={styles.block}>
      <div className={`container ${styles.wrapper}`}>
        <div className={styles.items_list}>
          {items.map((item, idx) => {
            if (item.name === 'search') return null
            return (
              <div
                key={idx}
                className={styles.item}
                onClick={() => handleClick(item.name)}
              >
                {item.value}
                <CloseSvg />
              </div>
            )
          })}
        </div>

        <div className={styles.total}>Total results: {count}</div>
      </div>
    </div>
  )
}
export default FilterIndicator
