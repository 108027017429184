import { VESSEL_MODAL } from '../action-types'
import { IVessel, IVesselFormValues } from '../../types/global.types'
import { toast } from 'react-toastify'
import { createVesselAction, updateVesselAction } from '../vessels/main.action'
import { postVessel, putVessel } from '../../api/vessels.api'
import { emptyStringToNull } from '../../helpers/api.helper'

export const setIsModalOpen = (payload: boolean) => {
  return {
    type: VESSEL_MODAL.SET_IS_OPEN,
    payload: payload,
  }
}

export const createNewVesselRequest = (vessel: IVesselFormValues) => (
  dispatch: any
) => {
  postVessel(
    emptyStringToNull({
      ...vessel,
      engine_type: vessel.engine_type?.id,
      flag: vessel.flag?.id,
      vessel_type: vessel.vessel_type?.id,
      owner: vessel.owner?.id,
      status: vessel.status,
    })
  ).then((data) => {
    dispatch(createVesselAction(data.id, data))
    dispatch(setIsModalOpen(false))
    toast.success(`${vessel.name || 'Vessel'} was created`)
  })
}

export const updateVesselRequest = (id: number, vessel: IVesselFormValues) => (
  dispatch: any
) => {
  putVessel(
    id,
    emptyStringToNull({
      ...vessel,
      engine_type: vessel.engine_type?.id,
      flag: vessel.flag?.id,
      vessel_type: vessel.vessel_type?.id,
      owner: vessel.owner?.id,
      status: vessel.status,
      created_by: vessel.created_by?.id,
    })
  ).then((response) => {
    dispatch(updateVesselAction(id, { ...vessel, status: response.status }))
    dispatch(setIsModalOpen(false))
    toast.success(`${vessel.name || 'Vessel'} was updated`)
  })
}

export const editVesselAction = (id: number, vessel: IVessel) => {
  return {
    type: VESSEL_MODAL.START_EDIT,
    payload: {
      id,
      vessel,
    },
  }
}
