import api from './index.api'
import { IVesselFormValues } from '../types/global.types'

const endpoint = `vessels/`

export const getVesselsList = async (query?: string) => {
  return await api.get(`${endpoint}?page_size=20&${query || ''}`)
}
export const getMoreVesselsList = async (url: string) => {
  const query = url.split('?')[1]
  return await api.get(`${endpoint}?${query}`)
}

export const deleteVessel = async (id: number) => {
  return await api.delete(`${endpoint}${id}/`)
}

export const postVessel = async (vessel: IVesselFormValues) => {
  return await api.post(`${endpoint}`, vessel)
}

export const putVessel = async (id: number, vessel: IVesselFormValues) => {
  return await api.put(`${endpoint}${id}/`, vessel)
}

export const postCombineVessel = async (
  parent: number,
  children: Array<number>
) => {
  return await api.post(`vessels-children/`, { parent, children })
}
