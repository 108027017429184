import React, { useEffect, useState } from 'react'
import styles from './HistoryModal.module.scss'
import Modal from '@material-ui/core/Modal'
import { setIsUiModalOpen } from '../../redux/actions/ui.action'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../redux/reducers/root.reducer'
import {
  HistoryModalType,
  IHistoryItemProps,
  IVessel,
  OptionItemType,
} from '../../types/global.types'
import { Button } from '@material-ui/core'
import { uuid } from '../../helpers/api.helper'

import CircularProgress from '@material-ui/core/CircularProgress'
import { submitParser } from './submit.helpter'
import { toast } from 'react-toastify'
import { CloseSvg } from '../Icons'

interface IHistoryFormModalProps {
  open: boolean
  name: string
  getItemsRequest: any
  postItemsRequest: any
  title: string
  itemComponent: React.FC<IHistoryItemProps>
  validationField: 'name' | 'owner'
}

const HistoryModal: React.FC<IHistoryFormModalProps> = ({
  open,
  name,
  getItemsRequest,
  title,
  postItemsRequest,
  validationField,
  itemComponent: Component,
}) => {
  const dispatch = useDispatch()
  const handleClose = () => {
    dispatch(setIsUiModalOpen(name, false))
  }

  const selected: IVessel = useSelector(
    (state: RootState) => state.main.list
  ).find((item: IVessel) => item.is_selected)

  const [state, setState] = useState<Array<HistoryModalType>>([])
  const [loading, setLoading] = useState<boolean>(false)
  useEffect(() => {
    setLoading(true)
    getItemsRequest(selected.id).then((history: Array<any>) => {
      setLoading(false)
      setState(history)
    })
  }, [selected.id, getItemsRequest])

  const handleFieldChange = (
    value: string | Date | null,
    id: number | string,
    name: string
  ) => {
    if (!state) return
    const newValues = state.map((item) => {
      if (item.id !== id) return item
      return {
        ...item,
        [name]: value,
      }
    })

    setState(newValues)
  }

  const onAddNew = () => {
    const newItem = {
      id: uuid(),
      name: '',
      date_from: null,
      port: null,
    }
    setState([...state, newItem])
  }

  const handleFieldSearchChange = (
    name: string,
    value: string | OptionItemType,
    id: string | number
  ): void => {
    setState(
      state.map((item) => {
        return {
          ...item,
          // @ts-ignore
          [name]: item.id === id ? value : item[name],
        }
      })
    )
  }

  const onDelete = (id: string | number) => {
    setState(state.filter((item) => item.id !== id))
  }

  function getResentDate(arr: Array<HistoryModalType>) {
    if (!arr.length) return null
    return arr.reduce((accum: HistoryModalType, current: HistoryModalType) => {
      if (accum.date_from && current.date_from) {
        return accum.date_from > current.date_from ? accum : current
      } else {
        return accum
      }
    })
  }
  const mainId = getResentDate(state)?.id

  const onApply = () => {
    if (mainId) {
      postItemsRequest(selected.id, submitParser(state, mainId)).then(() => {
        toast.success('history has been updated')
        dispatch(setIsUiModalOpen(name, false))
      })
    }
  }

  // @ts-ignore
  const isInvalid = !!state.find(
    (item) => !item[validationField] || !item.date_from
  )

  return (
    <Modal onClose={handleClose} open={open}>
      <div className={`modal-wrapper ${styles.wrapper}`}>
        <button onClick={handleClose} className="close_button">
          <CloseSvg />
        </button>
        <h1 className={styles.title}>{title}</h1>

        {loading ? (
          <div className={styles.loader_wrapper}>
            <CircularProgress />
          </div>
        ) : (
          <div className={styles.list}>
            {state &&
              state.map((vessel) => (
                <React.Fragment key={vessel.id}>
                  <Component
                    name={vessel.name || ''}
                    id={vessel.id}
                    port={vessel.port?.name || ''}
                    owner={vessel.owner?.name || ''}
                    date={vessel.date_from}
                    onChange={handleFieldChange}
                    isMain={mainId === vessel.id}
                    searchChange={handleFieldSearchChange}
                  />
                  {mainId !== vessel.id && (
                    <div className={styles.button_container}>
                      <Button
                        fullWidth
                        size="small"
                        variant="contained"
                        onClick={() => onDelete(vessel.id)}
                        color="primary"
                        style={{ width: '100%', background: '#8C0000' }}
                      >
                        Delete
                      </Button>
                    </div>
                  )}
                </React.Fragment>
              ))}
          </div>
        )}

        <div className={styles.button_container}>
          <Button
            onClick={onAddNew}
            fullWidth
            size="small"
            color="primary"
            variant="contained"
          >
            add new
          </Button>
        </div>
        <div className={styles.button_container}>
          <Button
            onClick={onApply}
            fullWidth
            size="small"
            color="primary"
            variant="contained"
            disabled={isInvalid}
          >
            apply
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default HistoryModal
