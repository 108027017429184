import React from 'react'
import styles from './LoginForm.module.scss'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { useDispatch } from 'react-redux'
import { userLogin } from '../../redux/user/user.action'

const validationSchema = yup.object().shape({
  email: yup.string().email('Invalid email').required('Email is required'),
  password: yup.string().required('Password is required'),
})

function LoginForm() {
  const dispatch = useDispatch()

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema,
    onSubmit: (values) => {
      dispatch(userLogin(values))
    },
  })

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    formik.handleChange(event)
  }

  const { errors, touched, values } = formik
  return (
    <form className={styles.form} onSubmit={formik.handleSubmit}>
      <h2 className={styles.title}>Authorization</h2>
      <div className={styles.field}>
        <TextField
          name="email"
          label="email"
          size="small"
          variant="outlined"
          onChange={handleInputChange}
          value={values.email}
          error={!!(errors.email && touched.email)}
          helperText={errors.email && touched.email ? errors.email : null}
        />
      </div>
      <div className={styles.field}>
        <TextField
          name="password"
          label="password"
          size="small"
          variant="outlined"
          onChange={handleInputChange}
          value={values.password}
          type="password"
          error={!!(errors.password && touched.password)}
          helperText={
            errors.password && touched.password ? errors.password : null
          }
        />
      </div>
      <div className={styles.field}>
        <Button type="submit" variant="contained" color="primary">
          Log in
        </Button>
      </div>
    </form>
  )
}

export default LoginForm
