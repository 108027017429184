import React from 'react'
import Header from '../components/Header/Header'
import ControlPanel from '../components/ControlPanel/ControlPanel'
import FilterIndicator from '../components/FilterIndicator/FilterIndicator'
import VesselsTable from '../components/VesselsTable/VesselsTable'
import VesselFormModal from '../components/VesselFormModal/VesselFormModal'
import { RootState } from '../redux/reducers/root.reducer'
import { useDispatch, useSelector } from 'react-redux'
import StatusEditorModal from '../components/StatusEditorModal/StatusEditorModal'
import { updateUserInformation } from '../redux/user/user.action'
import { getLeadsListRequest } from '../redux/vessels/main.action'
import MTModal from '../MTModal/MTModal'
import HistoryModal from '../components/HistoryModal/HistoryModal'
import {
  getVesselHistory,
  postVesselHistory,
} from '../api/vessels-name-history'
import { getOwnerHistory, postOwnerHistory } from '../api/vessels-owner-history'
import HistoryItem from '../components/HistoryModal/HistoryItem'
import OwnerHistoryItem from '../components/HistoryModal/OwnerHistoryItem'
import { setIsUiModalOpen } from '../redux/actions/ui.action'
import { Fab } from '@material-ui/core'
import InfoModal from '../components/InfoModal/InfoModal'

const Main = () => {
  const dispatch = useDispatch()
  const is_modal_open = useSelector(
    (state: RootState) => state.vessel_modal.is_open
  )
  const ordering = useSelector((state: RootState) => state.main.ordering)
  const filter = useSelector((state: RootState) => state.main.filter)
  const { status_editor, history, owner_history, info } = useSelector(
    (state: RootState) => state.ui.modals
  )

  const handleInfoClick = () => {
    dispatch(setIsUiModalOpen('info', true))
  }

  React.useEffect(() => {
    dispatch(
      getLeadsListRequest({
        ordering,
        ...filter,
        flag: filter.flag ? filter.flag.id : '',
        status: filter.status ? filter.status.id : '',
        user: filter.user ? filter.user.id : '',
        created_by: filter.created_by ? filter.created_by.id : '',
        owner: filter.owner ? filter.owner.id : '',
        vessel_type: filter.vessel_type ? filter.vessel_type.id : '',
        engine_type: filter.engine_type ? filter.engine_type.id : '',
      })
    )
  }, [dispatch, ordering, filter])

  React.useEffect(() => {
    dispatch(updateUserInformation())
  }, [dispatch])
  return (
    <>
      <div className="app">
        <Header />
        <ControlPanel />
        <FilterIndicator />
        <VesselsTable />
        <MTModal />

        {history.is_open && (
          <HistoryModal
            validationField="name"
            name="history"
            title="Vessel name history"
            open={history.is_open}
            itemComponent={HistoryItem}
            getItemsRequest={getVesselHistory}
            postItemsRequest={postVesselHistory}
          />
        )}

        {owner_history.is_open && (
          <HistoryModal
            validationField="owner"
            name="owner_history"
            title="Owner history"
            open={owner_history.is_open}
            itemComponent={OwnerHistoryItem}
            getItemsRequest={getOwnerHistory}
            postItemsRequest={postOwnerHistory}
          />
        )}
        {info.is_open && <InfoModal />}
        {is_modal_open && <VesselFormModal open={is_modal_open} />}
        <StatusEditorModal open={status_editor.is_open} />

        <div onClick={handleInfoClick} className="floating-button">
          <Fab size="small" color="primary" aria-label="add">
            <span className="floating-button-text">?</span>
          </Fab>
        </div>
      </div>
    </>
  )
}

export default Main
