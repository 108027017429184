import api from './index.api'
import { ILoginForm } from '../types/auth.types'
import { CLIENT_ID, CLIENT_SECRET } from '../constants/api'

export const userLoginRequest = async (data: ILoginForm) => {
  return await api.post(`token/`, {
    username: data.email,
    password: data.password,
    client_id: CLIENT_ID,
    client_secret: CLIENT_SECRET,
    grant_type: 'password',
    scope: 'stuff',
  })
}

export const getUserInfo = async () => {
  return await api.get(`me/`)
}
