import React from 'react'
import styles from './StatusEditorModal.module.scss'
import { useFormik } from 'formik'
import TextField from '@material-ui/core/TextField'
import { Button } from '@material-ui/core'
import ColorPicker from '../ColorPicker/ColorPicker'
import { useDispatch } from 'react-redux'
import {
  deleteStatus,
  postNewStatus,
  updateStatus,
} from '../../redux/actions/statusCreator.action'
import * as yup from 'yup'
import { IStatus } from '../../types/global.types'

interface IStatusEditorFormProps {
  data: IStatus | null
}

const validationSchema = yup.object().shape({
  name: yup.string().required('Status name is required'),
  color: yup.string().required('Color is required'),
})

const StatusEditorForm: React.FC<IStatusEditorFormProps> = ({ data }) => {
  const dispatch = useDispatch()

  const formik = useFormik({
    initialValues: data || {
      name: '',
      color: '',
      description: '',
    },
    validationSchema,
    onSubmit: (values) => {
      if (data && data.id) {
        dispatch(updateStatus(values))
      } else {
        dispatch(
          postNewStatus({
            name: values.name,
            color: values.color,
            description: values.description,
          })
        )
      }
    },
  })

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    formik.handleChange(event)
  }

  function handleColorChange(color: string): void {
    formik.setValues({
      ...formik.values,
      color,
    })
  }

  function handleDeleteClick(): void {
    if (data && data.id) {
      if (window.confirm(`Do you want to delete ${data.name || ''} status?`)) {
        dispatch(deleteStatus(data.id))
      }
    }
  }

  const { values, errors, touched } = formik

  return (
    <form className={styles.form} onSubmit={formik.handleSubmit}>
      <div className={styles.row}>
        <div className={styles.field}>
          <TextField
            name="name"
            label="Status"
            size="small"
            variant="outlined"
            onChange={handleInputChange}
            value={values.name}
            style={{ width: '100%' }}
            error={!!(errors.name && touched.name)}
            helperText={errors.name && touched.name ? errors.name : null}
          />
        </div>

        <ColorPicker
          invalid={!!(errors.color && touched.color)}
          color={formik.values.color}
          onChange={handleColorChange}
        />
      </div>

      <div className={styles.field}>
        <TextField
          name="description"
          label="Description"
          multiline
          rows={3}
          rowsMax={10}
          size="small"
          variant="outlined"
          onChange={handleInputChange}
          value={formik.values.description}
          style={{ width: '100%' }}
        />
      </div>
      <div className={styles.field_save}>
        <Button
          type="submit"
          style={{ width: '100%' }}
          variant="contained"
          color="primary"
        >
          save
        </Button>
      </div>
      {data && (
        <div className={styles.field_delete}>
          <Button
            color="primary"
            style={{ width: '100%', background: '#8C0000' }}
            variant="contained"
            onClick={handleDeleteClick}
          >
            delete
          </Button>
        </div>
      )}
    </form>
  )
}

export default StatusEditorForm
