import Root from '../../pages/Root'
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import { Provider } from 'react-redux'
import store from '../../redux/store'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'
import React from 'react'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#2D4170',
    },
    secondary: {
      main: '#81BB41',
    },
  },
})

if (!navigator.onLine) {
  toast.error('Your internet connection does`t exist. Check your connection.')
}

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Root />
        <ToastContainer />
      </ThemeProvider>
    </Provider>
  )
}

export default App
