import React from 'react'
import styles from './VesselsTable.module.scss'

import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../redux/reducers/root.reducer'
import VesselsTableItem from './VesselsTableItem'
import CircularProgress from '@material-ui/core/CircularProgress'
import { IVessel } from '../../types/global.types'
import { loadMoreVessels } from '../../redux/vessels/main.action'

const VesselsTableBody = () => {
  const dispatch = useDispatch()

  const vessels: Array<IVessel> = useSelector(
    (state: RootState) => state.main.list
  )
  const pagination: any = useSelector(
    (state: RootState) => state.main.pagination
  )
  const combine_mode: { is_active: boolean } = useSelector(
    (state: RootState) => state.main.combine_mode
  )

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    if (pagination.next && !combine_mode.is_active) {
      if (
        event.currentTarget.scrollTop + 900 >=
          event.currentTarget.scrollHeight &&
        !pagination.is_loading
      ) {
        dispatch(loadMoreVessels(pagination.next))
      } else {
        return null
      }
    }
  }

  return (
    <div className={styles.tbody} onScroll={handleScroll}>
      {(combine_mode.is_active
        ? vessels.filter((item) => item.is_selected)
        : vessels
      ).map((item: IVessel) => (
        <VesselsTableItem
          key={item.id}
          item={item}
          isCombineActive={combine_mode.is_active}
        />
      ))}
      {pagination.next && !combine_mode.is_active && (
        <div className={styles.scroll_loader}>
          <CircularProgress />
        </div>
      )}
    </div>
  )
}

export default VesselsTableBody
