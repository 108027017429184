import React from 'react'
import styles from './HistoryModal.module.scss'
import DatePicker from '../DatePicker'
import cn from 'classnames'
import FieldSearch from '../FieldSearch'
import { IHistoryItemProps, OptionItemType } from '../../types/global.types'
import { getOwnerAutocompleteSearch } from '../../api/search.api'

const OwnerHistoryItem: React.FC<IHistoryItemProps> = ({
  id,
  onChange,
  searchChange,
  owner,
  date,
  isMain,
}) => {
  function handlePickerChange(value: Date | null, name: string) {
    onChange(value, id, name)
  }
  function handleSearchChange(event: string, value: string | OptionItemType) {
    searchChange(event, value, id)
  }

  return (
    <div className={cn(styles.item_wrapper, { [styles.main]: isMain })}>
      <div className={styles.field}>
        <FieldSearch
          name="owner"
          label="Owner"
          altName="company"
          flat={false}
          value={owner}
          onChange={handleSearchChange}
          request={getOwnerAutocompleteSearch}
        />
      </div>
      <div className={styles.field}>
        <DatePicker
          id={id}
          value={date}
          name="date_from"
          label="Date from"
          onChange={handlePickerChange}
        />
      </div>
    </div>
  )
}

export default OwnerHistoryItem
