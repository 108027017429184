import React from 'react'
import styles from './InfoModal.module.scss'
import Modal from '@material-ui/core/Modal'
import { useDispatch } from 'react-redux'
import { setIsUiModalOpen } from '../../redux/actions/ui.action'
import { Button } from '@material-ui/core'

interface IInfoModalProps {
  isOpen?: boolean
}

const InfoModal: React.FC<IInfoModalProps> = ({ isOpen = true }) => {
  const dispatch = useDispatch()
  const handleClose = () => {
    dispatch(setIsUiModalOpen('info', false))
  }

  return (
    <Modal
      onClose={handleClose}
      open={isOpen}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div className={styles.block}>
        <p> Vessels system предназначена для хранения и коррекции судов.</p>

        <p>
          Для каждой новой записи отмечается ее создатель в поле Create by. По
          этому полю можно фильтровать записи. <br /> Фиксируется время создания
          записи. При обновлении записи фиксируется дата обновления и
          пользователь.
        </p>

        <p>
          {' '}
          В списке можно сортировать записи по всем полям. <br />
          В списках можно фильтровать записи при нажатии кнопки FILTER. <br />
          При вводе текста в поля фильтра всплывают подсказки из числа записей,
          находящихся в списке и позволяющие правильно выбрать критерий фильтра.
        </p>

        <p>
          Отметка Lead Status предназначена для отображения статуса записи,
          например, “Updated” или “Without Flag”. <br />
          Перечнем Lead Status можно управлять. Каждый Lead Status имеет цветную
          маркировку. <br />
          В таблице, при наведении курсора на цветную метку всплывает подсказка
          с названием Lead Status. <br />
          Каждая запись может иметь только одну отметку Lead Status. <br />
          При удалении Lead Status, записи, которые были им отмечены, теряют
          цветную маркировку.
        </p>

        <p>
          При объединении дублированных записей, необходимо отметить чекбоксы
          записей, которые должны быть совмещены и нажать кнопку COMBINE. После
          необходимо нажать кнопку Main рядом с главной записью, в которую будут
          перемещена информация с дублей. После этого дубли будут удалены из
          списка. Для отмены объединения необходимо нажать кнопку Cancel.
        </p>

        <p>
          Для исторического переименования судна необходимо отметить чекбокс
          записи и нажать кнопку HISTORY. <br />
          После этого можно отредактировать существующие записи о
          переименованиях или добавить новые.
        </p>

        <p>
          Для исторического переименования судовладельческой компании необходимо
          отметить чекбокс записи и нажать кнопку OWNER HISTORY. После этого
          можно изменить данные о судовладельце судна или добавить новые.
        </p>

        <p>
          Виджет Marine Traffic Search осуществляет поиск судов по базе данных
          сайта{' '}
          <a href="https://www.marinetraffic.com/">
            https://www.marinetraffic.com/
          </a>
        </p>
        <p>
          Предназначен для уточнения общедоступных данных о судне. Поиск
          осуществляется по наименованию судна или IMO номеру судна.
          Расположение виджета на странице можно изменить, перетащив его.
        </p>

        <p>
          Критерии фильтра можно удалять нажатием на кнопку, соответствующую
          критерию, в строке критериев фильтра Все пользователи имеют равные
          права при просмотре и редактировании списка.
        </p>

        <div className={styles.footer}>
          <Button onClick={handleClose} color="primary" variant="contained">
            Okay
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default InfoModal
