import { Switch } from 'react-router-dom'
import Login from './Login'
import PublicRoute from '../components/PublicRoute'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { addToken } from '../redux/user/user.action'
import PrivateRoute from '../components/PrivateRoute'
import Main from './Main'
import routes from '../constants/routes'

function Root() {
  const dispatch = useDispatch()

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem('access_token') || 'null')
    if (token) {
      dispatch(
        addToken({
          access_token: token,
          is_auth: true,
        })
      )
    }
  }, [dispatch])

  return (
    <>
      <Switch>
        <PublicRoute exact path={routes.login} component={Login} />
        <PrivateRoute exact path={routes.main} component={Main} />
      </Switch>
    </>
  )
}

export default Root
