import { FILTER, VESSELS } from '../action-types'
import { toast } from 'react-toastify'
import {
  IFilterValues,
  IStatus,
  IVessel,
  IVesselFormValues,
} from '../../types/global.types'
import {
  deleteVessel,
  getMoreVesselsList,
  getVesselsList,
  postCombineVessel,
} from '../../api/vessels.api'
import { objToQueryConverter } from '../../helpers/api.helper'
import { Dispatch } from 'react'
import { postSetStatuses } from '../../api/statuses.api'

export const getLeadsListRequest = (filter: any) => (dispatch: any) => {
  dispatch(setIsLoading(true))
  getVesselsList(objToQueryConverter(filter)).then((data) => {
    dispatch(updateVesselListAction(data.results))
    dispatch(
      updatePagination({
        count: data.count,
        next: data.next,
        prev: data.previous,
      })
    )
  })
}

export const deleteVesselRequest = (ids: Array<number>) => (dispatch: any) => {
  deleteVessel(ids[0]).then(() => {
    dispatch(deleteVesselAction(ids))
    toast.success('Vessel was deleted')
  })
}

export const loadMoreVessels = (url: string) => (dispatch: any) => {
  dispatch(updatePagination({ is_loading: true }))
  getMoreVesselsList(url).then((data) => {
    dispatch({
      type: VESSELS.LOAD_MORE,
      payload: data.results,
    })
    dispatch(
      updatePagination({
        is_loading: false,
        next: data.next,
        prev: data.previous,
      })
    )
  })
}

export const setStatusesThunk = (status: IStatus, ids: Array<number>) => (
  dispatch: Dispatch<any>
) => {
  if (status.id) {
    postSetStatuses(status.id, ids)
      .then(() => {
        dispatch(setStatusesAction(status, ids))
        dispatch(unselectAllLeads())
        toast.success('Statuses were updated!')
      })
      .catch(() => {
        toast.error('Something went wrong!')
      })
  }
}

// Action creators

export const setStatusesAction = (status: IStatus, ids: Array<number>) => ({
  type: VESSELS.SET_STATUSES,
  payload: {
    status,
    ids,
  },
})

export const setIsVesselSelectedAction = (id: number) => ({
  type: VESSELS.SET_IS_SELECTED,
  payload: id,
})

export const deleteVesselAction = (ids: Array<number>) => {
  return {
    type: VESSELS.DELETE,
    payload: ids,
  }
}

export const createVesselAction = (id: number, vessel: IVesselFormValues) => ({
  type: VESSELS.CREATE,
  payload: {
    id,
    vessel,
  },
})

export const updateVesselListAction = (payload: Array<IVessel>) => {
  return {
    type: VESSELS.UPDATE_LIST,
    payload,
  }
}

export const updateVesselAction = (id: number, vessel: IVesselFormValues) => {
  return {
    type: VESSELS.UPDATE,
    payload: {
      id,
      vessel,
    },
  }
}

const setIsLoading = (payload: boolean) => ({
  type: VESSELS.SET_IS_LOADING,
  payload,
})

// Filter
export const updateFilter = (payload: IFilterValues) => ({
  type: FILTER.UPDATE,
  payload,
})

export const updateFilterSearch = (payload: string) => ({
  type: FILTER.UPDATE_SEARCH,
  payload,
})

export const deleteFilterItem = (name: string) => ({
  type: FILTER.DELETE,
  payload: name,
})

export const updateFilterSortingAction = (payload: string) => ({
  type: FILTER.UPDATE_SORTING,
  payload,
})

// Pagination
export const updatePagination = (object: object) => ({
  type: VESSELS.UPDATE_PAGINATION,
  payload: object,
})

// Combine mode

export const applyCombineAction = (parent: number, children: Array<number>) => (
  dispatch: any
) => {
  postCombineVessel(parent, children).then(() => {
    toast.success('Leads were combined!')
    dispatch(deleteVesselAction(children))
    dispatch(toggleCombineMode())
  })
}

export const toggleCombineMode = () => {
  return {
    type: VESSELS.TOGGLE_COMBINE_MODE,
  }
}

export const selectAllLeadsAction = () => ({
  type: VESSELS.SELECT_ALL,
})

export const unselectAllLeads = () => ({
  type: VESSELS.UNSELECT_ALL,
})
