import { ThunkDispatch } from 'redux-thunk'
import { USER } from '../action-types'
import { ILoginForm } from '../../types/auth.types'
import { getUserInfo, userLoginRequest } from '../../api/auth.api'
import {
  AddTokenActionType,
  AddTokenPayloadType,
  UserLogoutActionType,
} from './types'

export const userLogin = (payload: ILoginForm) => (
  dispatch: ThunkDispatch<null, void, any>
) => {
  userLoginRequest(payload).then((data) => {
    localStorage.setItem('access_token', JSON.stringify(data.access_token))
    dispatch(
      addToken({
        access_token: data.access_token,
        is_auth: true,
      })
    )
  })
}
export const updateUserInformation = () => (
  dispatch: ThunkDispatch<null, void, any>
) => {
  getUserInfo()
    .then((data) => {
      dispatch({
        type: USER.UPDATE_INFO,
        payload: data,
      })
    })
    .catch(() => {
      dispatch(userLogout())
    })
}

export const userLogout = (): UserLogoutActionType => {
  localStorage.removeItem('access_token')
  return { type: USER.LOGOUT }
}

export const addToken = (payload: AddTokenPayloadType): AddTokenActionType => {
  return {
    type: USER.LOGIN,
    payload: payload,
  }
}
