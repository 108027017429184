import React, { useEffect, useState } from 'react'
import styles from './VesselFormModal.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../redux/reducers/root.reducer'
import { useFormik } from 'formik'
import FieldSearch from '../FieldSearch'
import {
  ICountry,
  IVesselFormValues,
  IStatus,
  OptionItemType,
} from '../../types/global.types'
import { CloseSvg } from '../Icons'
import {
  createNewVesselRequest,
  setIsModalOpen,
  updateVesselRequest,
} from '../../redux/actions/vessel-modal.action'
import { VesselFormModalSchema as validationSchema } from '../../constants/schemas'
import TextField from '@material-ui/core/TextField'
import Modal from '@material-ui/core/Modal'
import Button from '@material-ui/core/Button'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Autocomplete from '@material-ui/lab/Autocomplete'
import {
  getFiltersRequest,
  getOwnerAutocompleteSearch,
} from '../../api/search.api'

const VesselFormModal = ({ open }: { open: boolean }) => {
  const modal = useSelector((state: RootState) => state.vessel_modal)
  const [statuses, setStatuses] = useState([])
  const [engines, setEngines] = useState([])
  const [vesselTypes, setVesselTypes] = useState<
    Array<{ id: number; name: string }>
  >([])
  const [countries, setCountries] = React.useState<Array<ICountry>>([])

  const dispatch = useDispatch()
  const handleClose = () => {
    dispatch(setIsModalOpen(false))
  }

  useEffect(() => {
    getFiltersRequest(['status', 'country', 'vessel_type', 'engine_type']).then(
      ({ status, country, vessel_type, engine_type }) => {
        setStatuses(status)
        setCountries(country)
        setVesselTypes(vessel_type)
        setEngines(engine_type)
      }
    )
  }, [])
  const initialValues: IVesselFormValues = {
    name: modal.form?.name || '',
    imo: modal.form?.imo || '',
    year_of_build: modal.form?.year_of_build || '',
    flag: modal.form?.flag || null,
    status: modal.form?.status?.id || '',
    created_by: modal.form?.created_by || '',
    owner: modal.form?.owner || '',
    vessel_type: modal.form?.vessel_type || null,
    dwt: modal.form?.dwt || '',
    grt: modal.form?.grt || '',
    teu: modal.form?.teu || '',
    engine_type: modal.form?.engine_type || null,
    hp: modal.form?.hp || '',
    kw: modal.form?.kw || '',
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      if (modal.vessel_id) {
        dispatch(updateVesselRequest(modal.vessel_id, values))
      } else {
        dispatch(createNewVesselRequest(values))
      }
    },
  })

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | any>
  ): void => {
    formik.handleChange(event)
  }

  const handleFieldSearchChange = (
    name: string,
    value: string | OptionItemType
  ): void => {
    formik.handleChange({ target: { name, value } })
  }
  const { values, errors, touched } = formik
  return (
    <Modal
      onClose={handleClose}
      open={open}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      disablePortal={true}
    >
      <>
        <div className={styles.wrapper}>
          <div className={styles.modal}>
            <button onClick={handleClose} className="close_button">
              <CloseSvg />
            </button>
            <h2 className={styles.title}>
              {modal.vessel_id ? 'Update vessel' : 'New vessel'}
            </h2>

            <form onSubmit={formik.handleSubmit} id="vessel-form">
              <div className={styles.field}>
                <FieldSearch
                  required
                  withMt={!modal.vessel_id}
                  onChange={handleFieldSearchChange}
                  name="name"
                  label="Name"
                  value={values.name}
                />
              </div>

              <div className={styles.field}>
                <TextField
                  name="imo"
                  type="number"
                  InputProps={{ inputProps: { min: 1 } }}
                  label="IMO"
                  size="small"
                  required
                  variant="outlined"
                  onChange={handleInputChange}
                  value={values.imo}
                  error={!!(errors.imo && touched.imo)}
                  helperText={errors.imo && touched.imo ? errors.imo : null}
                />
              </div>

              <div className={styles.field}>
                <Autocomplete
                  id="country-cms"
                  options={countries}
                  clearOnBlur
                  getOptionLabel={(option: ICountry) => option.name}
                  value={values.flag}
                  onChange={(event: any, value: ICountry | null) => {
                    formik.setValues({ ...values, flag: value })
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Flag"
                      size="small"
                      variant="outlined"
                      inputProps={{
                        ...params.inputProps,
                      }}
                    />
                  )}
                />
              </div>

              <div className={styles.field}>
                <Autocomplete
                  id="vessel_type-vessel"
                  options={vesselTypes}
                  clearOnBlur
                  getOptionLabel={(option: { id: number; name: string }) =>
                    option.name
                  }
                  value={values.vessel_type}
                  onChange={(
                    event: any,
                    value: { id: number; name: string } | null
                  ) => {
                    formik.setValues({ ...values, vessel_type: value })
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Vessel type"
                      size="small"
                      variant="outlined"
                      inputProps={{
                        ...params.inputProps,
                      }}
                    />
                  )}
                />
              </div>

              <div className={styles.field}>
                <Autocomplete
                  id="engines_type-vessel"
                  options={engines}
                  clearOnBlur
                  getOptionLabel={(option: { id: number; name: string }) =>
                    option.name
                  }
                  value={values.engine_type}
                  onChange={(
                    event: any,
                    value: { id: number; name: string } | null
                  ) => {
                    formik.setValues({ ...values, engine_type: value })
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Engine type"
                      size="small"
                      variant="outlined"
                      inputProps={{
                        ...params.inputProps,
                      }}
                    />
                  )}
                />
              </div>

              <div className={styles.field}>
                <TextField
                  name="year_of_build"
                  label="Delivered year"
                  type="number"
                  InputProps={{ inputProps: { min: 1 } }}
                  size="small"
                  variant="outlined"
                  onChange={handleInputChange}
                  value={values.year_of_build}
                  error={!!(errors.year_of_build && touched.year_of_build)}
                  helperText={
                    errors.year_of_build && touched.year_of_build
                      ? errors.year_of_build
                      : null
                  }
                />
              </div>
              <div className={styles.field}>
                <FieldSearch
                  name="owner"
                  label="Operator"
                  required
                  altName="company"
                  flat={false}
                  value={values.owner?.name || ''}
                  onChange={handleFieldSearchChange}
                  request={getOwnerAutocompleteSearch}
                  error={!!(errors.owner && touched.owner)}
                  helperText={
                    errors.owner && touched.owner ? 'Field is required' : null
                  }
                />
              </div>

              <div className={styles.row}>
                <div className={styles.field}>
                  <TextField
                    name="dwt"
                    label="DWT"
                    size="small"
                    type="number"
                    InputProps={{ inputProps: { min: 1 } }}
                    variant="outlined"
                    onChange={handleInputChange}
                    value={values.dwt}
                    error={!!(errors.dwt && touched.dwt)}
                    helperText={errors.dwt && touched.dwt ? errors.dwt : null}
                  />
                </div>

                <div className={styles.field}>
                  <TextField
                    name="grt"
                    label="GRT"
                    type="number"
                    InputProps={{ inputProps: { min: 1 } }}
                    size="small"
                    variant="outlined"
                    onChange={handleInputChange}
                    value={values.grt}
                    error={!!(errors.grt && touched.grt)}
                    helperText={errors.grt && touched.grt ? errors.grt : null}
                  />
                </div>
                <div className={styles.field}>
                  <TextField
                    name="teu"
                    type="number"
                    InputProps={{ inputProps: { min: 1 } }}
                    label="TEU"
                    size="small"
                    variant="outlined"
                    onChange={handleInputChange}
                    value={values.teu}
                    error={!!(errors.teu && touched.teu)}
                    helperText={errors.teu && touched.teu ? errors.teu : null}
                  />
                </div>
              </div>

              <div className={styles.row}>
                <div className={styles.field}>
                  <TextField
                    name="hp"
                    label="HP"
                    type="number"
                    InputProps={{ inputProps: { min: 1 } }}
                    size="small"
                    variant="outlined"
                    onChange={handleInputChange}
                    value={values.hp}
                    error={!!(errors.hp && touched.hp)}
                    helperText={errors.hp && touched.hp ? errors.hp : null}
                  />
                </div>

                <div className={styles.field}>
                  <TextField
                    name="kw"
                    label="KW"
                    type="number"
                    InputProps={{ inputProps: { min: 1 } }}
                    size="small"
                    variant="outlined"
                    onChange={handleInputChange}
                    value={values.kw}
                    error={!!(errors.kw && touched.kw)}
                    helperText={errors.kw && touched.kw ? errors.kw : null}
                  />
                </div>
              </div>

              <div className={styles.field}>
                <FormControl variant="outlined" size="small">
                  <InputLabel>Lead status</InputLabel>
                  <Select
                    id="form-status-label"
                    name="status"
                    value={values.status}
                    onChange={handleInputChange}
                  >
                    <MenuItem value={''}>None</MenuItem>
                    {statuses.map((item: IStatus) => (
                      <MenuItem key={item.id} value={item.id}>
                        <span>{item.name}</span>
                        <figure
                          style={{
                            height: 14,
                            width: 14,
                            position: 'absolute',
                            right: '32px',
                            top: '50%',
                            transform: 'translate(0, -50%)',
                            borderRadius: '4px',
                            background: item.color,
                          }}
                        />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </form>
          </div>
        </div>
        <div className={`${styles.field} ${styles.button_wrapper}`}>
          <Button
            form="vessel-form"
            type="submit"
            variant="contained"
            color="primary"
          >
            Save
          </Button>
        </div>
      </>
    </Modal>
  )
}

export default VesselFormModal
