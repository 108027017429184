import React from 'react'
import DateFnsUtils from '@date-io/date-fns'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'

interface IDatePickerProps {
  label: string
  onChange: (data: Date | null, name: string) => void
  value: Date | string | null
  id: number | string
  name: string
}

const DatePicker: React.FC<IDatePickerProps> = ({
  label,
  onChange,
  value,
  id,
  name,
}) => {
  const handleDateChange = (date: Date | null) => {
    onChange(date, name)
  }

  return (
    <div className="picker-wrapper">
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          variant="inline"
          format="yyyy/MM/dd"
          margin="normal"
          size="small"
          name={name}
          autoOk
          inputVariant="outlined"
          id={`date-picker-${id}`}
          label={label}
          value={value}
          onChange={handleDateChange}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
      </MuiPickersUtilsProvider>
    </div>
  )
}

export default DatePicker
