import { USER } from '../action-types'
import { ActionTypes } from './types'

const initialState = {
  access_token: null,
  is_auth: false,
  info: {
    id: null,
    email: null,
    first_name: null,
    last_name: null,
  },
}

export default function userReducer(state = initialState, action: any) {
  switch (action.type) {
    case USER.LOGIN:
      return {
        ...state,
        ...action.payload,
      }

    case USER.LOGOUT:
      return {
        ...initialState,
        is_auth: false,
      }

    case USER.UPDATE_INFO:
      return {
        ...state,
        info: action.payload,
      }

    default:
      return state
  }
}
