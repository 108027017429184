import React from 'react'
import styles from './Filter.module.scss'
import Button from '@material-ui/core/Button'
import { FilterSvg } from '../Icons'
import FilterMore from './FilterMore'
import { ClickAwayListener } from '@material-ui/core'

interface IFilterProps {}

const Filter: React.FC<IFilterProps> = () => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false)
  return (
    <ClickAwayListener onClickAway={() => setIsOpen(false)}>
      <div className={styles.block}>
        <Button
          style={{ color: 'var(--regular-color)' }}
          onClick={() => setIsOpen(!isOpen)}
          startIcon={<FilterSvg size={12} fill="var(--regular-color)" />}
        >
          Filter
        </Button>
        {isOpen && <FilterMore onClose={() => setIsOpen(false)} />}
      </div>
    </ClickAwayListener>
  )
}

export default Filter
