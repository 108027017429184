export const objToQueryConverter = (obj: any): string => {
  return Object.keys(obj)
    .map((key) => {
      const title = key

      let value = Array.isArray(obj[key]) ? obj[key].join(',') : obj[key]

      return value ? title + '=' + value : 'empty'
    })
    .filter((el) => el !== 'empty')
    .join('&')
}

export const emptyStringToNull = (object: any) => {
  const result: any = { ...object }
  Object.keys(object).forEach((key) => {
    result[key] = object[key] ? object[key] : null
  })
  return result
}

export function uuid() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : r && 0x3
    return v.toString(16)
  })
}

export function utcToLocalDate(data: string) {
  const date = new Date(data)
  const isoDate = new Date(
    date.getTime() - date.getTimezoneOffset() * 60000
  ).toISOString()
  return datePrettier(isoDate)
}

export function datePrettier(str: string) {
  const date = new Date(str)

  const timeString = date.toLocaleTimeString('en-GB')
  const dateString = date.toLocaleDateString().replaceAll('/', '-')
  return `${dateString}, ${timeString}`
}
