import React from 'react'
import styles from './MTModal.module.scss'
import CircularProgress from '@material-ui/core/CircularProgress'
import { MTModalField } from './MTModalField'
import { MTVesselType } from '../types/global.types'
import { Button, Tooltip } from '@material-ui/core'

interface MTModalListProps {
  vessels: Array<MTVesselType> | null
  loading: boolean
  handleMoreInfoClick: (id: number) => void
  moreLoadingId: number | null
}

const MTModalList: React.FC<MTModalListProps> = ({
  vessels,
  loading,
  handleMoreInfoClick,
  moreLoadingId,
}) => {
  return (
    <div className={styles.vessels_list}>
      {loading ? (
        <div className={styles.zero_ui}>
          <CircularProgress />
        </div>
      ) : vessels === null ? (
        <div className={styles.zero_ui}>No recent search</div>
      ) : vessels.length === 0 ? (
        <div className={styles.zero_ui}>No results</div>
      ) : (
        vessels.map((vessel: MTVesselType) => (
          <div key={vessel.id} className={styles.vessel_info}>
            <Tooltip
              placement="bottom"
              title={
                <>
                  {vessel.photo_url && (
                    <img
                      className={styles.vessel_photo}
                      src={vessel.photo_url}
                      alt="not found"
                    />
                  )}
                </>
              }
            >
              <div className={styles.field_title}>{vessel.name}</div>
            </Tooltip>

            <MTModalField label="imo" value={vessel.imo} />
            <MTModalField label="type" value={vessel.vessel_type} />
            <MTModalField label="dwt" value={vessel.dwt} />
            <MTModalField label="flag" value={vessel.flag} />
            <MTModalField label="Delivered year" value={vessel.year_of_build} />

            {vessel.with_particulars ? (
              <>
                <MTModalField label="Summer DWT" value={vessel.summer_dwt} />
                <MTModalField label="Max speed" value={vessel.speed_max} />
                <MTModalField
                  label="Speed service"
                  value={vessel.speed_service}
                />
                <MTModalField
                  label="Technical manager"
                  value={vessel.technical_manager}
                />
                <MTModalField label="Liquid oil" value={vessel.liquid_oil} />
                <MTModalField label="LOA" value={vessel.loa} />
                <MTModalField
                  label="Financial owner"
                  value={vessel.financial_owner}
                />
                <MTModalField
                  label="Fuel consumption"
                  value={vessel.fuel_consumption}
                />
                <MTModalField
                  label="Displacement summer"
                  value={vessel.displacement_summer}
                />
                <MTModalField label="Draught" value={vessel.draught} />
                <MTModalField
                  label="Breadth extreme"
                  value={vessel.breadth_extreme}
                />
                <MTModalField label="manager" value={vessel.manager} />
                <MTModalField
                  label="Place of build"
                  value={vessel.place_of_build}
                />
              </>
            ) : (
              vessel.imo && (
                <div className={styles.vessel_info_footer}>
                  <Button
                    onClick={() => handleMoreInfoClick(vessel.ship_id)}
                    size="small"
                    variant="outlined"
                  >
                    {moreLoadingId === vessel.id ? (
                      <CircularProgress size={20} />
                    ) : (
                      'Add more info'
                    )}
                  </Button>
                </div>
              )
            )}
          </div>
        ))
      )}
    </div>
  )
}

export default MTModalList
