import * as yup from 'yup'

const IMO_CHAR_LENGTH = 7
const min_year_of_build = 1900
const max_year_of_build = new Date().getFullYear()

const isExact = (value: any, length: number) => {
  if (!value) return false
  return value.toString().length === length
}

const imo_validation_rules = yup
  .number()
  .required('IMO is required')
  .test('len', `Must be exactly ${IMO_CHAR_LENGTH} characters`, (val) =>
    isExact(val, IMO_CHAR_LENGTH)
  )
  .positive()
  .integer()

export const VesselFormModalSchema = yup.object().shape({
  imo: imo_validation_rules,
  dwt: yup.number(),
  grt: yup.number(),
  teu: yup.number(),
  hp: yup.number(),
  kw: yup.number(),
  year_of_build: yup
    .number()
    .min(min_year_of_build, `invalid year`)
    .max(max_year_of_build, `invalid year`),
  owner: yup.object().shape({
    id: yup.number().required(),
  }),
})

export const FilterModalSchema = yup.object().shape({
  imo: yup
    .number()
    .test('len', `Must be exactly ${IMO_CHAR_LENGTH} characters`, (val) => {
      return isExact(val, IMO_CHAR_LENGTH) || val === undefined
    }),
})
