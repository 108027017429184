import React from 'react'
import styles from './VesselsTable.module.scss'

import { useSelector } from 'react-redux'
import { RootState } from '../../redux/reducers/root.reducer'
import VesselsTableHeader from './VesselsTableHeader'
import CircularProgress from '@material-ui/core/CircularProgress'
import VesselsTableBody from './VesselsTableBody'
import { IVessel } from '../../types/global.types'

const VesselsTable: React.FC = () => {
  const loading = useSelector((state: RootState) => state.main.loading)

  const vessels: Array<IVessel> = useSelector(
    (state: RootState) => state.main.list
  )

  return (
    <div className={styles.table}>
      {loading && (
        <div className={styles.spinner_wrapper}>
          <CircularProgress />
        </div>
      )}
      <VesselsTableHeader />
      {!vessels.length ? (
        <div className={styles.no_content}>{!loading && 'Not found'}</div>
      ) : (
        <VesselsTableBody />
      )}
    </div>
  )
}

export default VesselsTable
