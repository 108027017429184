import React, { useState } from 'react'
import styles from './ColorPicker.module.scss'
import { colors } from './ColorPicker.helper'
import { Modal } from '@material-ui/core'
import cn from 'classnames'

interface IColorPickerProps {
  color: string | null
  onChange: (color: string) => void
  invalid?: boolean
}

const ColorPicker: React.FC<IColorPickerProps> = ({
  color,
  onChange,
  invalid = true,
}) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  function handleOpen() {
    setIsModalOpen(true)
  }

  function handleClick(color: string) {
    setIsModalOpen(false)
    onChange(color)
  }

  return (
    <div className={styles.wrapper}>
      <figure
        onClick={handleOpen}
        style={{
          background: color || 'transparent',
          border: color
            ? 'none'
            : `1px solid ${invalid ? 'tomato' : '#C4C4C4'}`,
        }}
        className={styles.picker}
      />
      <Modal onClose={() => setIsModalOpen(false)} open={isModalOpen}>
        <div className={styles.colors}>
          {colors.map((item: string) => (
            <figure
              key={item}
              onClick={() => handleClick(item)}
              style={{ background: item }}
              className={cn(styles.picker, {
                [styles.picker_active]: item === color,
              })}
            />
          ))}
        </div>
      </Modal>
    </div>
  )
}

export default ColorPicker
