import React, { useEffect } from 'react'
import styles from './StatusEditorModal.module.scss'
import Modal from '@material-ui/core/Modal'
import { Button } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { setIsUiModalOpen } from '../../redux/actions/ui.action'
import { ArrowLeftSvg, PlusSvg } from '../Icons'
import StatusEditorForm from './StatusEditorForm'
import {
  getStatusesList,
  setCurrentOnEdit,
} from '../../redux/actions/statusCreator.action'
import { RootState } from '../../redux/reducers/root.reducer'
import { IStatus } from '../../types/global.types'

const StatusEditorModal: React.FC<{ open: boolean }> = ({ open }) => {
  const dispatch = useDispatch()

  const { statuses, current_on_edit } = useSelector(
    (state: RootState) => state.statusCreator
  )

  const handleClose = () => {
    dispatch(setIsUiModalOpen('status_editor', false))
  }

  function handleStatusClick(data: IStatus) {
    dispatch(setCurrentOnEdit(data))
  }
  function handleCreateNew() {
    dispatch(setCurrentOnEdit('new status'))
  }

  function handleBackClick() {
    dispatch(setCurrentOnEdit(null))
  }

  useEffect(() => {
    dispatch(getStatusesList())
  }, [dispatch])

  return (
    <Modal onClose={handleClose} open={open}>
      <div className="modal-wrapper">
        {current_on_edit === 'new status' ? (
          <StatusEditorForm data={null} />
        ) : current_on_edit ? (
          <StatusEditorForm data={current_on_edit} />
        ) : (
          <div className={styles.list}>
            {statuses.map((item: IStatus) => (
              <div
                key={item.id}
                className={styles.list_item}
                onClick={() => handleStatusClick(item)}
              >
                <div className={styles.header}>
                  <div className={styles.status_name}>{item.name}</div>
                  <figure
                    style={{ background: item.color }}
                    className={styles.color}
                  />
                </div>
              </div>
            ))}
          </div>
        )}

        <div className={styles.footer_wrapper}>
          <div className={styles.footer}>
            {current_on_edit ? (
              <Button onClick={handleBackClick}>
                <ArrowLeftSvg size={24} fill="var(--regular-color)" />
              </Button>
            ) : (
              <Button onClick={handleCreateNew}>
                <PlusSvg size={24} fill="var(--regular-color)" />
              </Button>
            )}

            <Button onClick={handleClose} color="primary" variant="outlined">
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default StatusEditorModal
