import React from 'react'
import styles from './MTModal.module.scss'

interface Props {
  label: string
  value: string | number | null | undefined
}

export const MTModalField: React.FC<Props> = ({ label, value }) => {
  return value ? (
    <div className={styles.field}>
      <b>{label}:</b> <span className={styles.field_text}>{value}</span>
    </div>
  ) : null
}
