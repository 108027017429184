import { IStatus } from '../../types/global.types'
import { STATUS } from '../action-types'

const initialState = {
  statuses: [],
  current_on_edit: false,
}

export default function statusCreatorReducer(
  state = initialState,
  action: any
) {
  switch (action.type) {
    case STATUS.SET_ON_EDIT:
      return {
        ...state,
        current_on_edit: action.payload,
      }

    case STATUS.UPDATE_LIST:
      return {
        ...state,
        statuses: action.payload,
      }

    case STATUS.UPDATE:
      return {
        ...state,
        statuses: state.statuses.map((item: IStatus) =>
          action.payload.id === item.id ? action.payload : item
        ),
      }

    case STATUS.DELETE:
      return {
        ...state,
        statuses: state.statuses.filter(
          (item: IStatus) => item.id !== action.payload
        ),
      }

    default:
      return state
  }
}
