import { VESSEL_MODAL } from '../action-types'

const initialState = {
  is_open: false,
  form: null,
  vessel_id: null,
}
export default function vesselModalReducer(state = initialState, action: any) {
  switch (action.type) {
    case VESSEL_MODAL.SET_IS_OPEN:
      return {
        ...state,
        is_open: action.payload,
        form: null,
        lead_id: null,
      }

    case VESSEL_MODAL.START_EDIT:
      return {
        ...state,
        is_open: true,
        form: action.payload.vessel,
        vessel_id: action.payload.id,
      }

    default:
      return state
  }
}

// https://shipowners-api.nextship.net/api/v1/crm/search/?text=Alpha&field=owner
// https://shipowners-api.nextship.net/api/v1/crm/search/?text=d&field=company
