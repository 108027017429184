export function parseValue(filter: any, item: any) {
  switch (item) {
    case 'user':
      return `${filter[item].first_name} ${filter[item].last_name}`

    case 'flag':
    case 'vessel_type':
    case 'status':
    case 'engine_type':
    case 'owner':
      return filter[item].name

    default:
      return filter[item]
  }
}
