import api from './index.api'
import ownerApi from './shipowner.instance'
import { isIMO } from '../helpers/vessel.helper'

type filterValueType =
  | 'user'
  | 'country'
  | 'status'
  | 'vessel_type'
  | 'engine_type'

export const getFiltersRequest = async (models: Array<filterValueType>) => {
  return await api.get(`filters/?models=${models}`)
}

export const getAutocompleteSearch = async (
  text: string,
  field: string,
  isFlat: boolean = true,
  withMt: boolean = false
) => {
  const encoded_text = text.replaceAll('+', '%2B')

  return await api.get(
    `search/?text=${encoded_text}&field=${field}&flat=${isFlat}&with_mt=${withMt}`
  )
}

export const getOwnerAutocompleteSearch = async (
  text: string,
  field: string
) => {
  const encoded_text = text.replaceAll('+', '%2B')

  return await ownerApi.get(
    `search/?text=${encoded_text}&field=${field}&flat=false`
  )
}

export const MTSearchRequest = async (value: string) => {
  const type = isIMO(+value) ? 'imo' : 'vessel_name'

  return await api.get(`vessels-mt/?${type}=${value}`)
}

export const getMTParticulars = async (id: number) => {
  return await api.get(`vessels-mt-particulars/?ship_id=${id}`)
}
