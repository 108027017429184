import { Dispatch } from 'react'
import { toast } from 'react-toastify'
import { STATUS } from '../action-types'
import { IStatus } from '../../types/global.types'
import {
  deleteStatusRequest,
  getStatusesRequest,
  postNewStatusRequest,
  putStatusRequest,
} from '../../api/statuses.api'

export const getStatusesList = () => (dispatch: Dispatch<any>) => {
  getStatusesRequest().then((data) => {
    dispatch(updateStatusesList(data.results))
  })
}

export const postNewStatus = (status: IStatus) => (
  dispatch: any,
  getState: any
) => {
  const statuses = getState().statusCreator.statuses
  postNewStatusRequest(status).then((data) => {
    dispatch(updateStatusesList([...statuses, { ...status, id: data.id }]))
    dispatch(setCurrentOnEdit(null))
    toast.success(`Status '${status.name}' was created!`)
  })
}

export const updateStatus = (status: IStatus) => (dispatch: any) => {
  putStatusRequest(status).then((data) => {
    dispatch(updateStatusAction(data))
    dispatch(setCurrentOnEdit(null))
    toast.success(`Status '${data.name}' was updated!`)
  })
}

export const deleteStatus = (id: number) => (dispatch: any) => {
  deleteStatusRequest(id).then(() => {
    dispatch(deleteStatusAction(id))
    dispatch(setCurrentOnEdit(null))
    toast.success(`Status was deleted!`)
  })
}

// actions
export const setCurrentOnEdit = (data: IStatus | null | 'new status') => ({
  type: STATUS.SET_ON_EDIT,
  payload: data,
})

export const updateStatusesList = (data: Array<IStatus>) => ({
  type: STATUS.UPDATE_LIST,
  payload: data,
})

export const updateStatusAction = (data: IStatus) => ({
  type: STATUS.UPDATE,
  payload: data,
})

export const deleteStatusAction = (id: number) => ({
  type: STATUS.DELETE,
  payload: id,
})
