import React from 'react'
import styles from './VesselsTable.module.scss'
import cn from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../redux/reducers/root.reducer'
import { ArrowSvg } from '../Icons'
import {
  selectAllLeadsAction,
  unselectAllLeads,
  updateFilterSortingAction,
} from '../../redux/vessels/main.action'
import { IVessel } from '../../types/global.types'
import { Checkbox } from '@material-ui/core'

interface ILeadTableHeaderProps {}

const headTitles = [
  { value: 'name', label: 'name', sort_name: 'name' },
  { value: 'flag', label: 'flag', sort_name: 'flag__name' },
  { value: 'delivered', label: 'delivered', sort_name: 'year_of_build' },
  { value: 'imo', label: 'IMO', sort_name: 'imo' },
  { value: 'type', label: 'type', sort_name: 'vessel_type__name' },
  { value: 'dwt', label: 'DWT', sort_name: 'dwt' },
  { value: 'grt', label: 'GRT', sort_name: 'grt' },
  { value: 'teu', label: 'TEU', sort_name: 'teu' },
  { value: 'engine', label: 'engine', sort_name: 'engine_type__name' },
  { value: 'hp', label: 'HP', sort_name: 'hp' },
  { value: 'kw', label: 'KW', sort_name: 'kw' },
]
const disabledTitles = ['phone', 'email']

const VesselsTableHeader: React.FC<ILeadTableHeaderProps> = () => {
  const dispatch = useDispatch()
  const ordering: string | null = useSelector(
    (state: RootState) => state.main.ordering
  )

  const vessels: Array<IVessel> = useSelector(
    (state: RootState) => state.main.list
  )

  const combine_mode: { is_active: boolean } = useSelector(
    (state: RootState) => state.main.combine_mode
  )
  const isAllSelected = vessels.every((item: IVessel) => item.is_selected)

  function handleSortButtonClick(name: string) {
    const sort_name =
      headTitles.find((item) => item.value === name)?.sort_name || ''
    const sortString = ordering === sort_name ? `-${sort_name}` : sort_name
    dispatch(updateFilterSortingAction(sortString))
  }

  function handleCheckboxClick() {
    if (isAllSelected) {
      dispatch(unselectAllLeads())
    } else {
      dispatch(selectAllLeadsAction())
    }
  }

  return (
    <div className={styles.thead}>
      <div className={cn(styles.row, 'container')}>
        {headTitles.map((item, key) => (
          <div key={key} className={styles.field}>
            <div
              className={cn(styles.sort_button, {
                [styles.non_events]: disabledTitles.includes(item.value),
              })}
              onClick={() => handleSortButtonClick(item.value)}
            >
              {item.label}
              {ordering !== null &&
                ordering.replace('-', '') === item.sort_name && (
                  <ArrowSvg fill="#ffffff" rotate={ordering.includes('-')} />
                )}
            </div>
          </div>
        ))}

        <div className={styles.last_field}>
          <Checkbox
            disabled={combine_mode.is_active}
            size="small"
            checked={isAllSelected}
            onClick={handleCheckboxClick}
            color="secondary"
          />
        </div>
      </div>
    </div>
  )
}

export default VesselsTableHeader
