import { combineReducers } from 'redux'
import userReducer from '../user/user.reducer'
import mainReducer from '../vessels/main.reducer'
import vesselModalReducer from './vessel-modal.reducer'
import uiReducer from './ui.reducer'
import statusCreatorReducer from './statusCreator.reducer'

export const rootReducer = combineReducers({
  main: mainReducer,
  user: userReducer,
  vessel_modal: vesselModalReducer,
  ui: uiReducer,
  statusCreator: statusCreatorReducer,
})

export type RootState = ReturnType<typeof rootReducer>
