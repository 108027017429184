import React from 'react'

import LoginForm from '../components/LoginForm/LoginForm'

function Login() {
  return (
    <div className="login-page">
      <h1>Vessels System</h1>
      <LoginForm />
    </div>
  )
}

export default Login
