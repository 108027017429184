import React, { useState } from 'react'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { getAutocompleteSearch } from '../api/search.api'
import { Skeleton } from '@material-ui/lab'

interface IFieldSearchProps {
  onChange: (event: string, value: string | OptionItemType) => void
  name: string
  label: string
  value: string
  altName?: string | null
  request?: (value: string, name: string) => Promise<any>
  withMt?: boolean
  // other props
  [rest: string]: any
}

interface OptionItemType {
  id: number | null
  name: string | null
}

const FieldSearch: React.FC<IFieldSearchProps> = ({
  onChange,
  name,
  flat = true,
  label,
  value,
  altName = null,
  request = getAutocompleteSearch,
  withMt = false,
  ...rest
}) => {
  const [options, setOptions] = useState<Array<string>>([])
  const [noFlatArray, setNoFlatArray] = useState<Array<OptionItemType> | null>(
    null
  )
  function handleChange(event: React.ChangeEvent<{}>, value: string | null) {
    let result
    if (flat) {
      result = value || ''
    } else {
      if (noFlatArray) {
        result = noFlatArray.find((item) => item.name === value) || {
          name: value,
          id: null,
        }
      } else {
        result = {
          name: value,
          id: null,
        }
      }
    }

    onChange(name, result)
  }

  React.useEffect(() => {
    if (value !== '' && value.length > 2) {
      request(value, altName || name, flat, withMt).then((data) => {
        if (flat) {
          setOptions(data)
        } else {
          setOptions(data.map((item: OptionItemType) => item.name))
          setNoFlatArray(data)
        }
      })
    }
  }, [request, altName, value, name, flat, withMt])

  if (!flat && !noFlatArray && value.length > 3)
    return <Skeleton variant="rect" width="100%" height={40} />

  return (
    <>
      <Autocomplete
        options={[value, ...options]}
        value={value}
        closeIcon={false}
        autoSelect
        filterOptions={(options) =>
          options.filter((option, index) => option !== '' && index)
        }
        onInputChange={handleChange}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            size="small"
            name={name}
            variant="outlined"
            {...rest}
            inputProps={{
              ...params.inputProps,
              autoComplete: 'off',
            }}
          />
        )}
      />
    </>
  )
}

export default FieldSearch
