import api from './index.api'
import { HistoryModalType } from '../types/global.types'

const endpoint = 'vessels-owner-history/'

export const getOwnerHistory = async (id: number) => {
  return await api.get(`${endpoint}${id}/`)
}

export const postOwnerHistory = async (
  id: number,
  data: Array<HistoryModalType>
) => {
  return await api.post(`${endpoint}${id}/`, data)
}
