import React from 'react'
import styles from './MTModal.module.scss'
import Draggable, { DraggableData, DraggableEvent } from 'react-draggable'
import { ArrowSvg } from '../components/Icons'
import { Button, TextField } from '@material-ui/core'
import { getMTParticulars, MTSearchRequest } from '../api/search.api'
import { checkValidation } from './MTModal.helper'
import MTModalList from './MTModalList'
import { MTVesselType } from '../types/global.types'
import { getMTWidgetPosition, putMTWidgetPosition } from '../api/mt-widget.api'

export interface IMTPosition {
  x: number
  y: number
}

type validationType = {
  error: boolean
  message: string | null
}

const MTModal = () => {
  const [isOpen, setIsOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [moreLoadingId, setMoreLoadingId] = React.useState<number | null>(null)
  const [vessels, setVessels] = React.useState<Array<MTVesselType> | null>(null)
  const [position, setPosition] = React.useState<IMTPosition | null>(null)
  const [value, setValue] = React.useState('')
  const [validation, setValidation] = React.useState<validationType>({
    error: false,
    message: null,
  })

  React.useEffect(() => {
    getMTWidgetPosition()
      .then((position) => {
        setPosition(position)
      })
      .catch(() => setPosition({ x: 25, y: 5 }))
  }, [])

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value)
    setValidation(checkValidation(event.target.value))
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const validationResult = checkValidation(value)
    setValidation(validationResult)
    if (!validationResult.error) {
      setLoading(true)
      MTSearchRequest(value)
        .then((data) => {
          setVessels(data)
          setLoading(false)
        })
        .catch(() => {
          setVessels(null)
          setLoading(false)
        })
    }
  }

  function handleMoreInfoClick(id: number): void {
    setMoreLoadingId(id)
    getMTParticulars(id).then((data) => {
      setMoreLoadingId(null)
      if (vessels) {
        const updatedData = vessels.map((vessel) => {
          return vessel.ship_id === id ? data : vessel
        })
        setVessels(updatedData)
      }
    })
  }

  function handleOpenClick(event: React.SyntheticEvent<HTMLButtonElement>) {
    event.stopPropagation()
    setIsOpen(!isOpen)
  }

  function handleDragStopped(e: DraggableEvent, { x, y }: DraggableData) {
    putMTWidgetPosition({ x, y }).catch((error) => console.error(error))
    setPosition({ x, y })
  }

  return (
    position && (
      <Draggable
        bounds="parent"
        handle=".handle"
        onStop={handleDragStopped}
        position={position}
        defaultClassName={styles.modal}
      >
        <div>
          <h3 className={`${styles.title}`}>
            <div className={`handle ${styles.handle}`}>
              Marine Traffic Search
            </div>
            <button className={styles.open_button} onClick={handleOpenClick}>
              <ArrowSvg rotate={isOpen} />
            </button>
          </h3>
          {isOpen && (
            <div className={styles.content}>
              <form className={styles.form} onSubmit={handleSubmit}>
                <TextField
                  fullWidth
                  placeholder="Search"
                  variant="outlined"
                  size="small"
                  value={value || ''}
                  onChange={handleInputChange}
                  error={validation.error}
                  helperText={validation.message}
                />
                <Button
                  type="submit"
                  className={styles.button}
                  variant="outlined"
                  disabled={validation.error || loading}
                >
                  go
                </Button>
              </form>
              {!!vessels?.length && (
                <div className={styles.total}>
                  Total results: {vessels?.length}
                </div>
              )}
              <MTModalList
                handleMoreInfoClick={handleMoreInfoClick}
                moreLoadingId={moreLoadingId}
                loading={loading}
                vessels={vessels}
              />
            </div>
          )}
        </div>
      </Draggable>
    )
  )
}

export default MTModal
